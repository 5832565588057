import React from 'react';
import { Row, Col } from 'reactstrap';
import { InView } from 'react-intersection-observer'

class Support extends React.Component {
    onViewportChange(inView, id){
        if(document.getElementById(id).style.display === "block" && !inView){
            document.getElementById(id).style.display = "none";
        }
    }
    render() {

        return (
                <InView as="div" onChange={(inView, entry) => this.onViewportChange(inView, "supportWrapper")}>
                    <div  id="supportWrapper" style={{ display: 'none' }}>
                        <React.Fragment>
                            <section className="section" id="support">
                                <div className="container mt-5"  >
                                    <div className="row">
                                        <Col lg="12">
                                        <div className="title-heading mb-5">
                                            <h3 className="text-dark mb-1 font-weight-light text-uppercase">Support</h3>
                                            <div className="title-border-simple position-relative"></div>
                                        </div>
                                        </Col>
                                    </div>
                                    <Row>
                                        <Col lg="12">
                                        <div className="contact-box p-5">
                                            <Row>
                                                <Col lg="8" md="6">

                                                </Col>
                                            </Row>
                                        </div>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </React.Fragment>
                    </div>
                </InView>
                );
    }
}
export default Support;