import React from 'react';
import { Row, Col } from 'reactstrap';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";

class ClientSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            autoplay: true
        };
    }
    onSelect = (active, direction) => {
        console.log(`active=${active} && direction=${direction}`);
    };
    visiableOnSelect = active => {
        console.log(`visiable onSelect active=${active}`);
    };
    slideNext = () => {
        this.slider.slideNext();
    };
    slidePrev = () => {
        this.slider.slidePrev();
    };
    goToSlide = () => {
        this.slider.goToSlide(4);
    };
    autoplay = () => {
        this.setState({ autoplay: !this.state.autoplay });
    };
    _changeIcon = () => {
        let { leftIcon, rightIcon } = this.state;
        leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
        rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
        this.setState({ leftIcon, rightIcon });
    };
    render() {
        return (
            <React.Fragment>

                <section className="section bg-clients" id="clients">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-white mb-1 font-weight-light text-uppercase">Our Clients</h3>
                                    <div className="title-border-color position-relative"></div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col span={12} style={{ marginTop: 20 }}>
                                <RBCarousel
                                    version={4}
                                    autoplay={this.state.autoplay}
                                    pauseOnVisibility={true}
                                    onSelect={this.visiableOnSelect}
                                    slideshowSpeed={2000}
                                >
                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"><i>"Cosmic delivers"</i></h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">We asked Cosmic to build our next generation messaging product even before we knew exactly what that meant to us. They were very patient guiding us through the entire process and we're extremely happy with the end result. Cosmic delivers!</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-1.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Singular Telecom</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.singular.tel</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"><i>"Broad expertise"</i></h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">Managing thousands of SKUs of rapidly changing inventory, our existing systems weren't cutting it. Cosmic helped us figure out the bottlenecks, integrate better solutions, and even built us an app to streamline order fulfillment. It's amazing the broad expertise Cosmic brings to the table.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-2.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Mega Warehouse</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.megaware.house</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"><i>"There for us"</i></h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">We were working with tight deadlines to incrementally migrate customers to a completely new system that Cosmic built for us. Cosmic was there for us.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-3.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span>CFS</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.cfservices.ca</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"><i>"Bleeding edge"</i></h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">Cosmic helped us build a bleeding edge biometric system. We are now an industry leader with technology years ahead of our competition.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-4.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Secure Access Technologies</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.secureaccess.tech</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"><i>"Flexible"</i></h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">We are a software development firm that got in over our heads. Cosmic was so flexible. They helped us meet our deadline by completing some vital pieces of the project.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-5.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Coding Solutions</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.codingsolutions.ca</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light">"Full Service and Support"</h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">We've been benefiting from Cosmic's full service and support plan for years. Our clients are very demanding, but thanks in large part to Cosmic, also very happy.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            <div className="client-img">
                                                                <img src="images/clients/img-6.jpg" alt="" className="img-fluid rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Telepath</h6>
                                                            <p className="text-white-70 f-13 mb-0">www.telepath.ca</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBCarousel>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ClientSection;