import React from 'react';
import ScrollspyNav from './scrollSpy';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { Tab: '' };
    }
    /**
     * Sets active tab
     */
    setActiveTab = (tab, e) => {
        this.setState({ Tab: tab });
    }
    
    render() {

        return (
            <React.Fragment>
                <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                    <div className="container">
                        <a className="navbar-brand logo" href="/#home">
                            <img src="images/cosmic_network.png" alt="" height="20" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ScrollspyNav
                                scrollTargetIds={["home", "about", "services", "features", "clients", "contact", "terms", "privacy", "support", "eula"]}
                                defaultSectionId="home"
                                scrollHideableTargetIds={["terms", "privacy", "support", "eula"]}
                                activeNavClass="active"
                                scrollDuration="800"
                                headerBackground="true"
                                className="ml-auto">
                                <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                                    <li className="nav-item active"><a href="#home" className="nav-link" id="homeHeaderLink">Home</a></li>
                                    <li className="nav-item"><a href="#about" className="nav-link">Why Cosmic</a></li>
                                    <li className="nav-item"><a href="#services" className="nav-link">Services</a></li>
                                    <li className="nav-item"><a href="#features" className="nav-link">Projects</a></li>
                                    <li className="nav-item"><a href="#clients" className="nav-link">Clients</a></li>
                                    <li className="nav-item"><a href="#contact" className="nav-link">Contact us</a> </li>
									<li className="nav-item"><a href="#contact" className="nav-link" style={{ display: 'none' }} id="contactusHeaderLink">Contact us</a></li>
                                    <li className="nav-item"><a href="#terms" className="nav-link" style={{ display: 'none' }} id="termsHeaderLink">Terms</a> </li>
                                    <li className="nav-item"><a href="#privacy" className="nav-link" style={{ display: 'none' }} id="privacyHeaderLink">Privacy</a> </li>
                                    <li className="nav-item"><a href="#support" className="nav-link" style={{ display: 'none' }} id="supportHeaderLink">Support</a> </li>
                                    <li className="nav-item"><a href="#eula" className="nav-link" style={{ display: 'none' }} id="eulaHeaderLink">Eula</a> </li>
                                </ul>
                            </ScrollspyNav>
                        </div>
						
						
                    </div>
                </nav>
            </React.Fragment>
        );
    }

}

export default Navbar;