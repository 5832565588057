import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
	
	   onSupportClick(){
        document.getElementById("contactusHeaderLink").click();
    }
	
    render() {

        return (
            <React.Fragment>
									
                <section className="section bg-features bg-light" id="features">
				
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Projects</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>



                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-science text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Telecom</h4>
                                    <p className="text-muted f-14">From small interoperability projects to complete platforms, our telecom expertise spans over two decades.</p>
									<p className="mb-0 text-uppercase f-13"><Link className="text-primary" id="learnMore1" onClick={this.onSupportClick.bind(this)}>learn more<i className="mdi mdi-arrow-right ml-2"></i></Link></p>
																			
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
	
                    </div>
					
                </section>

                <section className="section bg-features">
                    <div className="container">
					
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/img-2.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-shuffle text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">e-Commerce</h4>
                                    <p className="text-muted f-14">We have a range of experience in e-commerce, from advertising and inventory management to payment and fulfillment and everything in between.</p>
                                   
                                    <p className="mb-0 text-uppercase f-13"><Link className="text-primary" id="learnMore1" onClick={this.onSupportClick.bind(this)}>learn more<i className="mdi mdi-arrow-right ml-2"></i></Link></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features bg-light">
                    <div className="container">
                        
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-display1 text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Back-End</h4>
                                    <p className="text-muted f-14">Whether it's shifting to the cloud or automating your back-office, our team has probably done something similar and can help you acheive your goal.</p>
                                   
                                    <p className="mb-0 text-uppercase f-13"><Link className="text-primary" id="learnMore1" onClick={this.onSupportClick.bind(this)}>learn more<i className="mdi mdi-arrow-right ml-2"></i></Link></p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-40">
                                    <img src="images/features-img/img-3.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>

                   </div>
                </section>
						
            </React.Fragment>
        );
    }
}
export default FeatureSection;