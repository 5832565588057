import React from 'react';

class Footer extends React.Component {

    onTermsLinkClick(){
        document.getElementById("termsHeaderLink").click();
    }
    
    onPrivacyLinkClick(){
        document.getElementById("privacyHeaderLink").click();
    }
    
    onSupportClick(){
        document.getElementById("supportHeaderLink").click();
    }
    
    onEulaClick(){
        document.getElementById("eulaHeaderLink").click();
    }
    
    render() {

        return (
            <React.Fragment>
                <section className="footer-bg">
                    <div className="container">
                       
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Footer;