import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FooterAlt extends React.Component {
	
	onTermsLinkClick(){
        document.getElementById("termsHeaderLink").click();
    }
    
    onPrivacyLinkClick(){
        document.getElementById("privacyHeaderLink").click();
    }
    
    onSupportClick(){
        document.getElementById("contactusHeaderLink").click();
    }
    
    onEulaClick(){
        document.getElementById("eulaHeaderLink").click();
    }
	
    render() {

        return (
            <React.Fragment>
                <section className="footer-alt bg-dark pt-3 pb-3">
                    <div className="container">
					 <Row  className="text-center">
                            <Col lg="4" className="f-14"><Link className="text-muted" id="termsLink" onClick={this.onTermsLinkClick.bind(this)}>Terms</Link></Col>
                            <Col lg="4" className="f-14"><Link className="text-muted" id="privacyLink" onClick={this.onPrivacyLinkClick.bind(this)}>Privacy</Link></Col>
                            <Col lg="4" className="f-14"><Link className="text-muted" id="supportLink" onClick={this.onSupportClick.bind(this)}>Support</Link></Col>
                        </Row>
					
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <p className="copyright text-white f-14 font-weight-light mb-0"> {new Date().getFullYear()} © Cosmic Network LLC</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default FooterAlt;