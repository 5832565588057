import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Creative Design",
                "content": "Out of box thinking, empowered by the diversity of our team and mandatory work from home policy, yields high product differentiation and your company's competitive advantage.",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Strategic Solutions",
                "content": "Unlike traditional software contracts that are often limited to solving short-sighted problems, our subscription model enables long-term solutions that evolve with your business.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Dynamic Growth",
                "content": "With our \"Always-On\" team of developers and use of modern, stable frameworks and scalable architecture, we build solutions to scale with and support your business into the future.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Why Cosmic</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.AboutList.map((about, index) => {
                                return <Col lg="4" key={index}>
                                            <div className="about-box about-light text-center p-3">
                                                <div className="about-icon mb-4">
                                                    <i className={about.icon}></i>
                                                </div>
                                                <h4 className="font-weight-light"><Link className="text-dark">{about.title}</Link></h4>
                                                <p className="text-muted f-14">{about.content}</p>
                                            </div>
                                        </Col>
                              })}
                         </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Perfect for Small and Medium Businesses</h3>
                                    <p className="text-muted f-15">As a company that started small, we get it. SMBs are in a unique position to offer their customers a more personalized, boutique experience. In today's technology-driven reality, that personalized experience often requires tools that aren't available off-the-shelf. Whether your solution involves integrating, extending, or customizing an off-the-shelf or existing solution, or creating an entirely new system, we'll help you get there.</p>
                                </div>
                            </Col>

                            <Col md="6">
                                <div className="about-img light-img position-relative p-4">
                                    <img src="images/about-img.jpg" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;