import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


class ServiceSection extends React.Component {
	
    onSupportClick(){
        document.getElementById("contactusHeaderLink").click();
    }
    constructor(props) {
        super(props);
        this.state = {
            ServiceList: [{
                "icon": "mdi mdi-database",
                "title": "Support",
                "content": "Our philosophy is that on going support and maintenance are part of any serious development project.",
            }, {
                "icon": "mdi mdi-cash-usd",
                "title": "Save your Capital",
                "content": "We'll develop your solution for a reasonable monthly fee, including future support and maintenance.",
            }, {
                "icon": "mdi mdi-cube-unfolded",
                "title": "Broad Expertise",
                "content": "Our team has broad industry experience across the Globe.",
            }, {
                "icon": "mdi mdi-speedometer",
                "title": "Migrations",
                "content": "We can work with your in-house team or directly with your solutions vendors to streamline migrations.",
            }, {
                "icon": "mdi mdi-image-filter-center-focus",
                "title": "Customizations",
                "content": "Extending or customizing your existing systems to better fit your business.",
            }, {
                "icon": "mdi mdi-hexagon-multiple",
                "title": "Complete Solutions",
                "content": "Large and small, we can work with you to provide complete solutions for your business.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section" id="services">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Services</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
					
                        <Row>
                            {this.state.ServiceList.map((service, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="service-box rounded mt-4 p-4">
                                        <div className="service-icon mb-3">
                                            <i className={service.icon}></i>
                                        </div>
                                        <div className="services-desc">
                                            <div className="service-title mb-2 position-relative">
                                                <h5 className="font-weight-normal mb-3"><Link to="#" className="text-dark">{service.title}</Link></h5>
                                            </div>
                                            <p className="text-muted mb-3 f-14">{service.content}</p>
                                            <p className="mb-0 text-uppercase f-13"><Link className="text-primary" id="learnMore" onClick={this.onSupportClick.bind(this)}>learn more<i className="mdi mdi-arrow-right ml-2"></i></Link>

											</p>
                                        </div>
                                    </div>
                                </Col>
                            })}

                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ServiceSection;