import React from 'react';
import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Home1 from './components/Home1';
import AboutSection from './components/AboutSection';
import ServiceSection from './components/ServiceSection';
import FeatureSection from './components/FeatureSection';
import ClientSection from './components/ClientSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Support from './components/Support';
import Eula from './components/Eula';



class Index1 extends React.Component {

  componentDidMount() {
        window.addEventListener('load', () => this.handleWindowLoaded());
        document.getElementById("main_navbar").classList.add("navbar-light");
  }
  
  handleWindowLoaded(){
        console.log(window.location);
        if(window.location.hash !== ""){
            switch(window.location.hash){
                case "#terms":
                    document.getElementById("termsHeaderLink").click();
                    break;
                case "#support":
                    document.getElementById("supportHeaderLink").click();
                    break;
                case "#privacy":
                    document.getElementById("privacyHeaderLink").click();
                    break;
                case "#eula":
                    document.getElementById("eulaHeaderLink").click();
                    break;
				default:
				    document.getElementById("homeHeaderLink").click();
				    break;
            }
        }else{
            document.getElementById("homeHeaderLink").click();
        }
  }

  render() {

    return (
      <React.Fragment>

        {/* preloader */}
        <Preloader />

        {/* Navigation Menu */}
		                             
        <Navbar />

            {/* HomeSection Menu */}
            <Home1 />

            {/* AboutSection Menu */}
            <AboutSection />

            {/* ServiceSection Menu */}
            <ServiceSection />

            {/* FeatureSection Menu */}
            <FeatureSection />

            {/* ClientSection Menu */}
            <ClientSection />

            {/* ContactSection Menu */}
            <ContactSection />
            
            <Terms/>
            
            <Privacy/>
            
            <Support/>
            
            <Eula/>
            
            {/* Footer Menu */}
                    
            <Footer />	
        
        {/* FooterAlt Menu */}
        <FooterAlt />

      </React.Fragment>

    );
  }
}

export default Index1;